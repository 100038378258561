import React, {useState} from "react";

import "./style.scss";

const CSProductCategory = ( { productcategory } ) => {

    console.log(productcategory);
    if (productcategory){
    const subCategories = productcategory.nodes;
    
    const ProductCategories= [];
    subCategories.map( ( cat ) => {
        if( cat.count >= 1 ) {
            let name = cat.name;
            let link = cat.slug;
            ProductCategories.push({name: name, link: link});
        }
    });
}

const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    

    if(event.currentTarget.id=="serums-list"){
        event.currentTarget.classList.add('active');

    
        document.getElementById('list-serums').classList.add('show');
        document.getElementById('list-serums').classList.add('active');

        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="bestseller-list"){
        event.currentTarget.classList.add('active');

        document.getElementById('list-bestseller').classList.add('show');
        document.getElementById('list-bestseller').classList.add('active');

        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="moisturizers-list"){
        event.currentTarget.classList.add('active');

        document.getElementById('list-moisturizers').classList.add('show');
        document.getElementById('list-moisturizers').classList.add('active');

        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="eye-treatments-list"){
        event.currentTarget.classList.add('active');

        document.getElementById('list-eye-treatments').classList.add('show');
        document.getElementById('list-eye-treatments').classList.add('active');

        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="cleansers-list"){
        event.currentTarget.classList.add('active');

        document.getElementById('list-cleansers').classList.add('show');
        document.getElementById('list-cleansers').classList.add('active');
        
        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="exfoliators-list"){
        event.currentTarget.classList.add('active');

        document.getElementById('list-exfoliators').classList.add('show');
        document.getElementById('list-exfoliators').classList.add('active');


        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="cleansers-list"){
        event.currentTarget.classList.add('active');
        document.getElementById('list-cleansers').classList.add('show');
        document.getElementById('list-cleansers').classList.add('active');
        

        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('sunscreen-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-sunscreen').classList.remove('active');

    }else if(event.currentTarget.id=="sunscreen-list"){
        event.currentTarget.classList.add('active');
        document.getElementById('list-sunscreen').classList.add('show');
        document.getElementById('list-sunscreen').classList.add('active');

        document.getElementById('serums-list').classList.remove('active');
        document.getElementById('bestseller-list').classList.remove('active');
        document.getElementById('moisturizers-list').classList.remove('active');
        document.getElementById('eye-treatments-list').classList.remove('active');
        document.getElementById('exfoliators-list').classList.remove('active');
        document.getElementById('cleansers-list').classList.remove('active');

        document.getElementById('list-serums').classList.remove('active');
        document.getElementById('list-bestseller').classList.remove('active');
        document.getElementById('list-moisturizers').classList.remove('active');
        document.getElementById('list-eye-treatments').classList.remove('active');
        document.getElementById('list-exfoliators').classList.remove('active');
        document.getElementById('list-cleansers').classList.remove('active');

    }
    
    
  };


    return (


        <div class="container-fluid cs-concerns test">
        <div class="concerns-list-group">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="link-list">
                        <div class="head">
                            products
                        </div>
                        <div class="list-group cs-list-tab" id="list-tab" role="tablist">
                                                            <a class="list-group-item list-group-item-action active" id="bestseller-list" data-toggle="list" href="#list-bestseller" role="tab" aria-controls="bestseller" aria-selected="true" onClick={handleClick}>
                                    <span>Best Sellers<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action" id="serums-list" data-toggle="list" href="#list-serums" role="tab" aria-controls="serums" aria-selected="false" onClick={handleClick}>
                                    <span>Serums<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action " id="moisturizers-list" data-toggle="list" href="#list-moisturizers" role="tab" aria-controls="moisturizers" onClick={handleClick}>
                                    <span>Moisturizers<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action " id="eye-treatments-list" data-toggle="list" href="#list-eye-treatments" role="tab" aria-controls="eye-treatments" onClick={handleClick}>
                                    <span>Eye Treatments<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action " id="cleansers-list" data-toggle="list" href="#list-cleansers" role="tab" aria-controls="cleansers" onClick={handleClick}>
                                    <span>Cleanser<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action " id="exfoliators-list" data-toggle="list" href="#list-exfoliators" role="tab" aria-controls="exfoliators" onClick={handleClick}>
                                    <span>Exfoliators<sup></sup></span>
                                </a>
                                                                                        <a class="list-group-item list-group-item-action " id="sunscreen-list" data-toggle="list" href="#list-sunscreen" role="tab" aria-controls="sunscreen" onClick={handleClick}>
                                    <span>Sunscreen<sup></sup></span>
                                </a>
                                                                                </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="content-list">
                    <div class="tab-content" id="nav-tabContent">
                                                            <div class="tab-pane fade active show" id="list-bestseller" role="tabpanel" aria-labelledby="bestseller-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                             <a href="https://www.carrotstick.com/product/the-repair-serum/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="name">
                                                                The Repair Serum                                                            </a>
                                                            <div class="overview">
                                                                This high-performance serum is designed to lift, tighten and firm...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                             <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="name">
                                                                The Defense Serum                                                            </a>
                                                            <div class="overview">
                                                                Packed with the antioxidant superpower of three vitamin C derivatives...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-defense-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                              <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="name">
                                                                The Moisturizer                                                            </a>
                                                            <div class="overview">
                                                                This lightweight antioxidant formula is a hydrating powerhouse...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                                                                                             </div>-->
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="img">
                                                                                                                                <img width="300" height="300" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="name">
                                                                The Repair Cream. For Dry Skin.                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Experience a skincare cream, uniquely formulated to nurture and protect skin’s microbiome. The microbiome is the ecosystem of microbes that live on the skin surface. Happy microbes equal a healthy microbiome and a healthy microbiome equals healthier-looking, more radiant skin. If it becomes imbalanced, acne, eczema, dryness and other skin issues can appear. Our proprietary formulation of probiotics and natural, soothing plant extracts keep the microbiome operating at peak performance and skin looking hydrated and visible issues diminished. Fragrance Free. Formulated with love for all skin types, especially dry.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">Experience a skincare cream, uniquely formulated to nurture and... </span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                
                                                            <div class="tab-pane fade" id="list-serums" role="tabpanel" aria-labelledby="serums-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                          <a href="https://www.carrotstick.com/product/the-brightening-spot-treatment-2/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2021/11/BrighteningSpotTreatment_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2021/11/BrighteningSpotTreatment_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-brightening-spot-treatment-2/" class="name">
                                                                The Brightening Spot Treatment                                                            </a>
                                                            <div class="overview">
                                                                Two highly effective plant-based compounds combine to correct the look of...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-brightening-spot-treatment-2/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="name">
                                                                The Repair Serum                                                            </a>
                                                            <div class="overview">
                                                                This high-performance serum is designed to lift, tighten and firm...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                         <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                                                                                <img width="240" height="300" src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="name">
                                                                The Defense Serum                                                            </a>
                                                            <div class="overview">
                                                                Packed with the antioxidant superpower of three vitamin C derivatives...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-defense-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="img">
                                                                                                                                <img width="300" height="300" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Acne-Spot-Serum-300x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Acne-Spot-Serum-300x300.png" class="img-fluid" data-done="Loaded" />
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="name">
                                                                The Acne Spot Treatment Serum                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This potent, yet gentle treatment serum allows you to target and clear blemishes and spots. Formulated with 2% blemish-fighting Salicylic Acid along with soothing and redness reducing Chamomile Flower and Green Tea Leaf Extracts. This topical treatment serum will reduce the appearance of redness and inflammation while it gently exfoliates the area, whisking away dirt and bacteria. Formulated with love for all skin types, including acne prone.\n\nWARNINGS: For external use only. Avoid eye area. When using this product, avoid using with topical acne medications at the same time as skin irritation and dryness is more likely to occur. If irritation occurs, only use one topical acne medication at a time. Keep out of reach of children. If swallowed get medical or contact a poison control center immediately. Do not apply to broken skin.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This potent, yet gentle treatment serum allows you to target and clear...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-moisturizers" role="tabpanel" aria-labelledby="moisturizers-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                              <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="name">
                                                                The Moisturizer                                                            </a>
                                                            <div class="overview">
                                                                This lightweight antioxidant formula is a hydrating powerhouse...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                             <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="img">
                                                                                                                                <img width="300" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="name">
                                                                The Repair Cream. For Dry Skin.                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Experience a skincare cream, uniquely formulated to nurture and protect skin’s microbiome. The microbiome is the ecosystem of microbes that live on the skin surface. Happy microbes equal a healthy microbiome and a healthy microbiome equals healthier-looking, more radiant skin. If it becomes imbalanced, acne, eczema, dryness and other skin issues can appear. Our proprietary formulation of probiotics and natural, soothing plant extracts keep the microbiome operating at peak performance and skin looking hydrated and visible issues diminished. Fragrance Free. Formulated with love for all skin types, especially dry.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">Experience a skincare cream, uniquely formulated to nurture and... </span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                           
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                                                                                <img width="300" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" data-src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="name">
                                                                The Mineral Sunscreen SPF 50                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated with mineral sunscreen; there are no harsh chemicals that can harm skin and the environment, including our oceans. Specially formulated with Titanium Dioxide and Zinc Oxide to protect skin against the harmful effects of UVA and UVB which can hasten the effects of skin aging, it also contains natural hydrators and antioxidants including Vitamin E, Green Tea Extract and Hawaiian Kukui Nut Oil to keep skin nourished and smooth. No greasy feel after application and the water-resistant formula delivers protection throughout the day with reapplication. Formulated with love for all skin types.\n-Helps prevent sunburn\n-If used as directed with other sun protection measures (see Directions), deceases the risk of skin cancer and early skin aging caused by the sun.\n\nWARNINGS: For external use only. When using this product, keep out of eyes. Rinse with warm water to remove. Do not use on damaged or broken skin. Stop use and consult a doctor if irritation or rash occurs. Keep out of the reach of children. If swallowed, get medical help or contact a Poison Control Center immediately. Note: If allergy-prone, pregnant, or lactating, consult your physician before use. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>48</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                              <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/03/WaterGelMoisturizer@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="name">
                                                                The Water Gel Moisturizer                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This multi-action gel moisturizer targets excess shine, blemishes and prominent pores, while helping to support more even-toned skin. Designed for normal-to-oily and breakout-prone skin types, the lightweight formula includes 10% niacinamide to balance sebum production, promote elasticity and bring flawless clarity to the skin. The bouncy, jelly-like texture creates a light veil of moisture while brightening dull skin and delivering an even, healthy appearance. Formulated with love for normal, dry, oily and combination skin. &quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This multi-action gel moisturizer targets excess shine, blemishes...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-eye-treatments" role="tabpanel" aria-labelledby="eye-treatments-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="name">
                                                                The Eye Cream                                                            </a>
                                                            <div class="overview">
                                                                This revolutionary treatment uses highly effective botanicals...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-cream/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            <a href="https://www.carrotstick.com/product/the-eye-serum/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheEyeSerum@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-serum/" class="name">
                                                                The Eye Serum                                                            </a>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-cleansers" role="tabpanel" aria-labelledby="cleansers-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img width="300" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This gentle and effective oil-free cleanser is formulated with natural plant extracts and natural hydrators, including hyaluronic acid, that won’t strip precious oils, disrupting the surface barrier. Coconut-based surfactants work into a luxurious lather. Skin will look and feel nourished and soft to the touch. Antioxidant-packed ingredients help protect against environmental stressors that can cause the appearance of fine lines and wrinkles. Does not contain harsh sulfates that can irritate skin. Fragrance Free. Formulated with love for all skin types, including sensitive.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                         <a href="https://www.carrotstick.com/product/the-face-wash/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/01/FaceWash_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-face-wash/" class="name">
                                                                The Face Wash                                                            </a>
                                                            <div class="overview">
                                                                Gentle yet potent botanicals create a wonderfully emollient cleanser...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>30</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-face-wash/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-exfoliators" role="tabpanel" aria-labelledby="exfoliators-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                           
                                                            <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="img">
                                                                                                                                <img width="300" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/02/The-Honey-Amber-Exfoliating-Polish-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="name">
                                                                The Honey Amber Exfoliating Polish                                                            </a>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                           
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheExfoliatingPeel@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="name">
                                                                The Exfoliating Peel                                                            </a>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                             <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="img">
                                                                                                                                <img width="240" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2021/11/BrighteningPads_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="name">
                                                                The Brightening Pads                                                            </a>
                                                            <div class="overview">
                                                                Reveal a fresh, smooth and radiant complexion with...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-sunscreen" role="tabpanel" aria-labelledby="sunscreen-list">
                                    <div class="row cs-content">
                                                                                        <div class="col last-big-card  cs-col">
                                                    <div class="product-card">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                                                                                <img width="300" height="300" data-class="LazyLoad" src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="name">
                                                                The Mineral Sunscreen SPF 50                                                            </a>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated with mineral sunscreen; there are no harsh chemicals that can harm skin and the environment, including our oceans. Specially formulated with Titanium Dioxide and Zinc Oxide to protect skin against the harmful effects of UVA and UVB which can hasten the effects of skin aging, it also contains natural hydrators and antioxidants including Vitamin E, Green Tea Extract and Hawaiian Kukui Nut Oil to keep skin nourished and smooth. No greasy feel after application and the water-resistant formula delivers protection throughout the day with reapplication. Formulated with love for all skin types.\n-Helps prevent sunburn\n-If used as directed with other sun protection measures (see Directions), deceases the risk of skin cancer and early skin aging caused by the sun.\n\nWARNINGS: For external use only. When using this product, keep out of eyes. Rinse with warm water to remove. Do not use on damaged or broken skin. Stop use and consult a doctor if irritation or rash occurs. Keep out of the reach of children. If swallowed, get medical help or contact a Poison Control Center immediately. Note: If allergy-prone, pregnant, or lactating, consult your physician before use. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>48</bdi></span>                                                            </div>
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )

}

export default CSProductCategory;
