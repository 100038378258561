import React from "react";
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import "./style.scss"

const CSProductBundles = () => {
    return (
        <>
        <Helmet>
        <script src={withPrefix('bundle.js')} type="text/javascript" />
        </Helmet>
        <div class="product-bundle-container">
        <div class="product-bundle-header">
        <h2><span>Bundles</span> & save up to $152</h2>
        </div>

    <div class="product-bundle-snippet">
        
        <div class="bundle-stcok">Bundles <span>(Total 2)</span></div>
        <div class="bundle-view-all"><a href="/bundles">View all</a></div>

    </div>
    <div class="product-bundle-two-col">
            
                <div class="single-product-bundle-cntnr">   
                
                    <div class="single-product-bundle">
            
                        <div class="single-product-bundle-header">
                            <div class="pb-title">Restore</div>
                            <div class="pb-price"><span><strike>$200</strike></span><span class="pb-offer-price">$88</span></div>

                        </div>

                        <div class="single-product-bundle-body">
                            
                            <div class="pb-images">
                            <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png"/>
                            <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png"/>
                            <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png"/>
                            </div>
                            <div class="pb-advantages">
                            <ul>
                                    <li>The Moisturizer</li><li>The Eye Cream</li><li>The Repair Serum</li>                               
                            </ul>
                            </div>
                            <div class="pb-shop-cta">
                                <a href="javascript:void(0);" onclick="addRoutinesToCart('', '', '', '');">Shop Bundle — $88</a>
                            </div>


                        </div>

                    </div>
                    </div>
                    <div class="single-product-bundle-cntnr">
                    <div class="single-product-bundle">
            
                        <div class="single-product-bundle-header">
                            <div class="pb-title">Protect</div>
                            <div class="pb-price"><span><strike>$190</strike></span><span class="pb-offer-price">$88</span></div>

                        </div>

                        <div class="single-product-bundle-body">
                            
                        <div class="pb-images">
                                <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png"/>
                                <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png"/>
                                <img src="https://cs1testdev.wpengine.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png"/>                           
                        </div>
                        <div class="pb-advantages">
                        <ul>
                        <li>The Moisturizer</li>
                        <li>The Defense Serum</li>
                        <li>The Eye Cream</li>                                
                        </ul>
                            </div>
                            <div class="pb-shop-cta">
                                <a href="javascript:void(0);" onclick="addRoutinesToCart('', '', '', '');">Shop Bundle — $88</a>
                            </div>


                        </div>

                    </div>

                    

                </div>
           
        </div>

        <div class="pb-shop-single">
                        <a href="/products">Shop individual products</a>
                    </div>

        
    </div>
    </>
    )
}

export default CSProductBundles;