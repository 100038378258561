import React from "react";
import "./style.scss"

const CSOfferBanner = () => {
    return (
        <div className="the-offer-banner">
            <h2>$79+ complimentary shipping within the U.S and Canada</h2>
        </div>
    )
}

export default CSOfferBanner;